@use './config/' as *;
@forward "./main-scss/";
.no-scroll {
    overflow: hidden;
}
.waf-nivia-sponser {
    // Local variables start
    $angles: 80deg, 145deg, 190deg, 240deg, 300deg, 20deg;
    $index : 1;
    --_radius: 10.5rem;
    --_text-gradient-color: linear-gradient(90deg, var(--accent-100) 0%, var(--accent) 20.65%, var(--accent-light) 34.41%, var(--accent-light) 50.3%, var(--accent-light) 65.65%, var(--accent) 83.65%, var(--accent-100) 101.65%);
    // Local variables end
    padding: 0 var(--space-2);
    margin-bottom: 0;
    @extend %relative;
    &:before {
        content: "";
        width: var(--content-width);
        z-index: var(--z-negative);
        @include background("nivia/pattern/pattern-mob.png", hsl(var(--hsl-secondary-dark)) no-repeat top/100% 100%);
        @include position(0, null, null, var(--container-white-space-neg), absolute);
        @extend %h-100;
        @extend %absolute;
    }
    .head {
        @extend %mb-6;
        &-wrap-info {
            @extend %d-none;
            @extend %uppercase;
        }
        .title {
            @extend %white;
            @extend %text-center;
            @extend %font-22-pb;
            @extend %uppercase;
        }
        .text {
            @extend %font-18-pb;
        }
    }
    .btn-discover {
        cursor: pointer;
        align-self: center;
        @extend %flex-c-c;
        @extend %mt-7;
        .btn-text {
            @extend %primary-bg;
            @extend %white;
            @extend %font-14-pr;
            @extend %p-1-2;
        }
    }
    .btn-buy {
        padding: .7rem var(--space-7);
        @extend %primary-bg;
        @extend %white;
        @extend %font-12-pr;
        @extend %d-inline-block;
        @extend %capitalize;
    }
    .banner {
        &-content {
            display: grid;
        }
        &-logo {
            grid-area: 1 / 2 / 2 / 3;
            @extend %flex-n-c;
        }
        &-section {
            @extend %py-6;
        }
        &-wrap {
            flex-direction: column-reverse;
            @extend %flex-column-n-c;
            @extend %gap-6;
        }
        &-info {
            grid-area: 1 / 1 / 2 / 2;
            @extend %white;
            @extend %mb-6;
            .text {
                @extend %white-5;
                @extend %font-12-pr;
                @extend %mb-2;
            }
            .title {
                background: var(--_text-gradient-color);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                line-height: 2rem;
                @extend %font-22-pb;
                @extend %uppercase;
                @extend %relative;
                @extend %d-inline-block;
                @extend %pb-3;
                &:after {
                    content: "";
                    background: linear-gradient(90deg, var(--white) 0%, var(--neutral) 100%);
                    height: .1rem;
                    @extend %position-b-l;
                    @extend %w-100;
                }
            }
            .sub-title {
                @extend %font-14-pb;
                @extend %uppercase;
            }
        }
        &-data {
            grid-area: 2 / 1 / 3 / 3;
            @extend %white;
            .title {
                line-height: 2.4rem;
                @extend %font-22-pb;
                @extend %uppercase;
            }
            .text {
                @extend %font-12-pr;
                @extend %mb-2;
            }
        }
        &-thumbnail {
            aspect-ratio: 1 / 1;
            width: var(--_thubnail-width, 20rem);
            flex-shrink: 0;
            @extend %relative;
            &::after {
                content: "";
                aspect-ratio: 1 / 1;
                width: 26rem;
                z-index: var(--z-negative);
                @include background("nivia/pattern/ball-bg-pattern.png", top/contain no-repeat);
                @extend %position-center;
            }
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
    .feature {
        &-section {
            @extend %py-6;
        }
        &-thumbnail {
            aspect-ratio: 1 / 1;
            width: 16rem;
            flex-shrink: 0;
            margin: var(--space-6) auto;
            img {
                height: 100%;
                width: 100%;
            }
        }
        &-content {
            .accordion {
                &-head {
                    @extend %flex-sb-c;
                    @extend %w-100;
                    @extend %mb-3;
                    .btn-text {
                        @extend %font-16-pr;
                        @extend %white-5;
                    }
                    &::after {
                        @include icon(chevron-down, 18);
                        @extend %white-5;
                    }
                    &[aria-expanded="true"] {
                        .btn-text {
                            font-weight: 700;
                            @extend %white;
                        }
                        &::after {
                            transform: rotate(180deg);
                            @extend %white;
                        }
                        & ~ .accordion-body {
                            grid-template-rows: 1fr;
                            @extend %pb-2;
                        }
                    }
                }
                &-body {
                    display: grid;
                    grid-template-rows: 0fr;
                    transition: grid-template-rows 500ms;
                    @extend %font-14-pr;
                    &-wrap {
                        @extend %hidden;
                        .text {
                            @extend %white-5;
                            @extend %font-14-pr;
                        }
                    }
                }
                &-item {
                    @extend %mb-3;
                    &:not(:last-child) {
                        border-bottom: .1rem solid clr(white, 2);
                    }
                }
            }
        }
    }
    .techinfo {
        &-section {
            --_value: 10.5rem;
            @extend %py-6;
            .body {
                @extend %flex-column-c-c;
                @extend %gap-8;
            }
        }
        &-data {
            height: auto;
            width: 28rem;
            @extend %primary-bg;
            @extend %p-6-4;
            @extend %relative;
            .title {
                @extend %white;
                @extend %font-20-pb;
                @extend %relative;
                @extend %pb-1;
                &:after {
                    content: "";
                    width: 4rem;
                    height: .2rem;
                    @extend %position-b-l;
                    @extend %white-bg;
                    @extend %absolute;
                }
            }
            .text {
                @extend %font-14-pr;
                @extend %white;
            }
            .btn-close {
                @include position(1.5rem, 1.5rem, null, null, absolute);
                &::after {
                    @include icon(close, 12);
                    @extend %white;
                }
            }
        }
        &-data-wrapper {
            z-index: var(--z-modal);
            @include position(0, null, null, 0, fixed);
            @extend %h-100;
            @extend %w-100;
            @extend %black-bg-6;
            @extend %d-none;
        }
        &-thumbnail {
            aspect-ratio: 1 / 1;
            width: 21rem;
            @extend %mx-auto;
        }
        &-thumbnail-container {
            @extend %relative;
            @extend %d-none;
            &.active {
                @extend %d-block;
            }
        }
        &-thumbnail-list {
            .techinfo-thumbnail-item.active {
                transform: unset;
            }
        }
        &-thumbnail-item {
            aspect-ratio: 1 / 1;
            width: 2.8rem;
            inset: 0;
            outline: .5rem solid clr(white, 2);
            @extend %circle-radius;
            @extend %m-auto;
            @extend %absolute;
            @extend %white-bg;
            .btn-add {
                @extend %h-100;
                @extend %w-100;
                @extend %flex-c-c;
                &:after {
                    @include icon(add-icon, 12);
                    @extend %primary;
                    @extend %transition;
                }
            }
            .btn-text {
                @extend %font-0;
            }
            &.active {
                .techinfo-data-wrapper {
                    @extend %flex-c-c;
                }
            }
            @each $angle in $angles {
                &:nth-of-type(#{$index}) {
                    transform: translate(calc(cos(#{$angle}) * var(--_radius)),
                        calc(sin(#{$angle}) * var(--_radius)));
                }
                $index : $index + 1;
            }
        }
        &-tabs-list {
            display: grid;
            grid-template-columns: 15rem 15rem;
            grid-template-rows: 15rem 15rem;
            justify-content: center;
            @extend %pl-0;
        }
        &-tabs-item {
            border: .1rem solid clr(white, 2);
            cursor: pointer;
            @extend %flex-c-c;
            @extend %relative;
            .image {
                width: 13rem;
                aspect-ratio: 1 / 1;
                @extend %p-2;
            }
            &.active {
                @extend %secondary-light-bg-7;
                .image {
                    --border-width: .1rem;
                    --color: #0000 25%, var(--primary) 0;
                    background:
                        conic-gradient(from 90deg at top var(--border-width) left var(--border-width), var(--color)) 0 0,
                        conic-gradient(from 180deg at top var(--border-width) right var(--border-width), var(--color)) 100% 0,
                        conic-gradient(from 0deg at bottom var(--border-width) left var(--border-width), var(--color)) 0 100%,
                        conic-gradient(from -90deg at bottom var(--border-width) right var(--border-width), var(--color)) 100% 100%;
                    background-size: 2.5rem 2.5rem;
                    background-repeat: no-repeat;
                    @extend %p-2;
                }
            }
        }
    }
    .ballhub {
        &-content {
            @extend %flex-column;
        }
        &-section {
            @extend %py-6;
        }
        &-img {
            aspect-ratio: 1 / 1;
            width: 16rem;
        }
        &-info {
            @extend %text-center;
            .title {
                @extend %white;
                @extend %font-18-pb;
                @extend %mb-1;
            }
            .text {
                @extend %font-14-pr;
                @extend %white;
            }
        }
        &-list {
            display: grid;
            @extend %gap-12;
            @extend %relative;
            &::after {
                content: "";
                background: linear-gradient(90deg, var(--secondary-dark) 0%, clr(white, 3) 50.11%, var(--secondary-dark) 100%);
                height: .1rem;
                @extend %position-y-center;
                @extend %w-100;
            }
        }
        &-item {
            @extend %flex-column-n-c;
            @extend %gap-3;
        }
    }
}
@include mq(col-md) {
    .waf-nivia-sponser {
        &:before {
            left: var(--space-2-neg);
            @include bgImg("nivia/pattern/pattern-web.png");
        }
        .banner {
            &-wrap {
                flex-direction: row-reverse;
            }
            &-section {
                padding-block: var(--space-12);
            }
            &-logo {
                width: 6rem;
                @include position(7rem, var(--container-white-space), null, null, absolute);
            }
        }
        .feature {
            &-section {
                .body {
                    display: grid;
                    grid-template-columns: 1fr 25rem;
                    gap: var(--space-6);
                }
            }
            &-thumbnail {
                .image {
                    object-fit: contain;
                }
            }
        }
        .techinfo {
            &-data {
                padding-block: var(--space-4);
            }
            &-section {
                .body {
                    flex-direction: row-reverse;
                }
            }
        }
        .ballhub {
            &-section {
                .body {
                    gap: var(--space-3);
                }
            }
            &-list {
                grid-template-columns: repeat(2, 1fr);
                &::after {
                    width: .1rem;
                    height: 100%;
                    background: linear-gradient(0deg, var(--secondary-dark) 0%, clr(white, 3) 50.11%, var(--secondary-dark) 100%);
                    @include position-combo(center);
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-nivia-sponser {
        --_radius: 17rem;
        .head {
            margin-bottom: var(--space-10);
            &-wrap {
                align-items: center;
                @include grid(2, 6rem);
            }
            &-wrap-info {
                display: block;
                color: var(--white);
            }
            .title {
                font-size: 4rem;
                margin-bottom: 0;
                line-height: 4.5rem;
                position: relative;
                @include flex-config(flex, column, flex-start, end);
                &:after {
                    content: "";
                    width: .1rem;
                    height: calc(100% + 2rem);
                    background: clr(white, 2);
                    right: -3rem;
                    @include position-combo("y-center");
                }
            }
            .year {
                font-size: 1.6rem;
            }
            .text {
                background: var(--_text-gradient-color);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .banner {
            &-thumbnail {
                --_thubnail-width: 40rem;
                &::after {
                    width: 50rem;
                }
            }
            &-info {
                .title {
                    font-size: 3.6rem;
                    line-height: 3.6rem;
                }
                .sub-title {
                    font-size: 1.8rem;
                }
            }
            &-data {
                .title {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }
                .text {
                    font-size: 1.4rem;
                }
            }
            &-thumbnail {
                margin-right: var(--space-20);
                margin-top: var(--space-10);
            }
            &-logo {
                width: 10rem;
                top: 4rem;
            }
        }
        .feature {
            &-section {
                .body {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            &-thumbnail {
                width: 40rem;
                margin-block: 0;
            }
            &-content {
                font-size: 2rem;
                .accordion {
                    &-head,
                    &-item {
                        margin-bottom: var(--space-4);
                    }
                }
            }
        }
        .techinfo {
            &-section {
                .body {
                    gap: var(--space-16);
                    justify-content: space-around;
                }
            }
            &-tabs-list {
                grid-template-columns: 20rem 20rem;
                grid-template-rows: 20rem 20rem;
            }
            &-data-wrapper {
                display: block;
                z-index: var(--z-default);
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.5s ease, visibility 0.5s;
                @include position(-2rem, null, null, 4rem, absolute);
                &:after {
                    content: "";
                    aspect-ratio: 1 / 1;
                    width: 2rem;
                    background-color: var(--primary);
                    transform: rotate(45deg);
                    z-index: var(--z-negative);
                    @include position(2.5rem, null, null, -1rem, absolute);
                }
                .btn-close {
                    display: none;
                }
            }
            &-thumbnail-item {
                z-index: var(--z-default);
                &:nth-of-type(4):hover + .techinfo-tabs-item {
                    z-index: -1;
                }
                &:hover {
                    z-index: var(--z-nivia-plus-hover);
                    background-color: var(--primary);
                    .btn-add::after {
                        color: var(--white);
                    }
                    .techinfo-data-wrapper {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &-data {
                width: 20rem;
            }
            &-tabs-item {
                width: 20rem;
                .image {
                    width: 18rem;
                }
            }
            &-thumbnail {
                width: 34rem;
            }
        }
        .ballhub {
            &-img {
                width: 28rem;
            }
        }
    }
}